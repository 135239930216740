<template>
  <g fill="none" fill-rule="evenodd">
    <g :fill="color" fill-rule="nonzero">
      <g>
        <g>
          <path
            d="M7.197 14.473c-2.832 0-5.515-2.475-5.944-5.504-.43-3.029 1.565-5.431 4.393-5.428 2.828.004 5.514 2.44 5.943 5.46.43 3.018-1.533 5.476-4.392 5.472z"
            transform="translate(-1303 -1157) translate(1043 1136) translate(260 21)"
          />
          <path
            d="M3.264 16.377h8.28c.038.003.076.003.114 0 .162.003.317-.06.432-.173.115-.113.179-.268.178-.43.28-1.547.623-3.06.965-4.607.078-.324.093-.66.045-.99L12.16 2.295c-.162-1.153.506-2.143 1.575-2.27.106-.017.212-.026.319-.025 1.002.045 1.893.654 2.298 1.572.145.347.234.715.263 1.09l1.18 8.329c.068.426.055.862-.037 1.284l-1.68 7.803c-.032.218-.032.44 0 .657l.485 3.424c.111.79-.1 1.038-.879 1.038l-13.953.004c-.748 0-1.039-.232-1.146-1.039l-.54-3.738c-.308-2.403.993-4.047 3.22-4.047z"
            transform="translate(-1303 -1157) translate(1043 1136) translate(260 21)"
          />
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
